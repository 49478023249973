<template>
  <sub-nav :pages="pages" />
</template>

<script>
export default {
  name: 'subNavForms',
  data() {
    return {}
  },
  computed: {
    pages() {
      return [
        {
          title: 'Completed forms',
          icon: 'thumbnails-list',
          to: '/forms'
        },
        {
          title: 'Form templates',
          icon: 'clipboard',
          to: '/form_templates'
        }
      ]
    }
  },
  props: {},
  components: {}
}
</script>
